import Home from "./pages/home";

//console.log(window.location.host)
function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;